@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");

/* @font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-Regular.eot");
  src: url("./fonts/Gilroy-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Gilroy-Regular.woff2") format("woff2"),
    url("./fonts/Gilroy-Regular.woff") format("woff"),
    url("./fonts/Gilroy-Regular.ttf") format("truetype"),
    url("./fonts/Gilroy-Regular.svg#Gilroy-Regular") format("svg");
  font-weight: 400;
}
@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-Medium.eot");
  src: url("./fonts/Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Gilroy-Medium.woff2") format("woff2"),
    url("./fonts/Gilroy-Medium.woff") format("woff"),
    url("./fonts/Gilroy-Medium.ttf") format("truetype"),
    url("./fonts/Gilroy-Medium.svg#Gilroy-Medium") format("svg");
  font-weight: 500;
}
@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-SemiBold.eot");
  src: url("./fonts/Gilroy-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Gilroy-SemiBold.woff2") format("woff2"),
    url("./fonts/Gilroy-SemiBold.woff") format("woff"),
    url("./fonts/Gilroy-SemiBold.ttf") format("truetype"),
    url("./fonts/Gilroy-SemiBold.svg#Gilroy-SemiBold") format("svg");
  font-weight: 600;
}
@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-Bold.eot");
  src: url("./fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Gilroy-Bold.woff2") format("woff2"),
    url("./fonts/Gilroy-Bold.woff") format("woff"),
    url("./fonts/Gilroy-Bold.ttf") format("truetype"),
    url("./fonts/Gilroy-Bold.svg#Gilroy-Bold") format("svg");
  font-weight: 700;
}
@font-face {
  font-family: "rage";
  src: url("./fonts/rage_1-webfont.woff2") format("woff2"),
    url("./fonts/rage_1-webfont.woff") format("woff");
  font-weight: 400;
} */

body {
  font-size: 16px;
  color: #0b0b1a;
  line-height: 24px;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  background-color: #f9f9f9;
}

a {
  text-decoration: none;
  color: #000;
}

a:hover {
  color: #f26a2f;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span {
  font-weight: 700;
  color: #f26a2f;
}

h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong {
  font-weight: 700;
}

.padd-40 {
  padding: 40px 0;
}

.padd-50 {
  padding: 50px 0;
}

.padd-60 {
  padding: 60px 0;
}

.padd-80 {
  padding: 80px 0;
}

.padd-100 {
  padding: 100px 0;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 38px;
  margin-bottom: 15px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

.title-heading {
  margin-bottom: 15px;
}

.title-heading p {
  margin: 0;
  font-weight: 400;
  font-family: "Poppins";
  color: #000000;
  text-transform: uppercase;
}

a.btn.btn-fill,
.btn.btn-fill {
  background-color: #f26a2f;
  border: 1px solid #f26a2f;
  color: #fff;
  border-radius: 5px;
  padding: 0 30px;
  line-height: 36px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: none;
}

a.btn.btn-fill:hover {
  background-color: #ffffff;
  color: #f26a2f;
  border-color: #f26a2f;
}

button.btn.btn-fill,
.btn.btn-fill {
  background-color: #f26a2f;
  border: 1px solid #f26a2f;
  color: #fff;
  border-radius: 5px;
  padding: 0 30px;
  line-height: 36px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: none;
}

button.btn.btn-fill:hover {
  background-color: #ffffff;
  color: #f26a2f;
  border-color: #f26a2f;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 99;
  padding: 12px 0;
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100% !important;
  background: rgb(0 0 0 / 70%);
  padding: 12px 0px;
}

.row.visible-title {
  max-width: 1480px;
  margin: 0 auto;
}

.pagetitle {
  padding: 220px 0 80px 0;
  text-align: center;
  color: #fff;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  display: inline-block;
  width: 100%;
}

.pagetitle h1 {
  font-size: 38px;
}

.breadrm {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
}

.breadrm a {
  color: #fff;
}

.search-icon img {
  max-width: 35px;
  margin-right: 10px;
  /* Extra Added */
  cursor: pointer;
}

.col-sm-8.menu-col {
  display: inline-flex;
  align-items: center;
  justify-content: end;
  position: relative;
}

.toggle-form.menu-active {
  display: block;
}

.toggle-form {
  position: absolute;
  top: 40px;
  right: 64px;
  display: none;
}

.toggle-form input {
  height: 46px;
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 16px;
  font-family: "Quicksand";
  width: 100%;
  margin-bottom: 15px;
  outline: none;
}

button.menu-toggle span {
  background-color: #fff;
  width: 36px;
  height: 3px;
  margin: 5px 0;
  display: block;
  border-radius: 5px;
}

button.menu-toggle {
  background-color: transparent;
  border: 0;
  float: right;
}

.nav-btn {
  background-color: transparent;
  color: white;
  border: none;
  margin-right: 10px;
  font-size: 20px;
  font-weight: 500;
}

header .row {
  align-items: center;
}

img {
  max-width: 100%;
  height: auto;
}

.banner-box p {
  font-size: 18px;
  font-weight: 400;
}

.page-not-found-box {
  width: 50%;
}

.page-not-found-box p {
  font-size: 18px;
  font-weight: 400;
}

.home-slider {
  /* background: url("./images/banner-bg-pic.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  /* EXTRA ADDED */
  height: 100vh;
}

.page-not-found {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
}

.banner-text-boxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 120px;
  min-height: 100vh;
  padding-bottom: 50px;
}

.page-not-found-text-boxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 120px; */
  min-height: 100vh;
  /* padding-bottom: 50px; */
}

.bg-sections,
.bg-sections img {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  height: 100%;
  object-fit: cover;
}

.relative {
  position: relative;
}

.bg-sections {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.full-section {
  display: inline-block;
  width: 100%;
  padding: 50px 0;
}

.buidingsection {
  text-align: center;
  padding: 40px 0;
}

.buidingsection h3 {
  margin-bottom: 30px;
}

.yearsof-success {
  text-align: center;
}

.yearsof-success h4 span {
  display: block;
  font-size: 46px;
}

.altr-pics img {
  width: 100%;
  border-radius: 10px;
}

.altr-about .row {
  align-items: center;
}

.three-colrobox {
  margin: 0px;
}

.three-colrobox .col-sm-12 {
  background-color: #ffd2be;
  padding: 40px;
  border-radius: 10px;
}

.three-colrobox .whatwe {
  background-color: #ff9a6e;
}

.altr-about-row {
  padding: 60px 0;
}

footer {
  background-color: #ffeae1;
  color: #fff;
}

.footermiddle {
  padding-top: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.footermiddle ul.footer-links {
  margin-bottom: 40px;
  padding: 0;
}

footer a,
footer {
  color: #000;
}

.footermiddle ul.footer-links li {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
}

footer h4 {
  font-size: 20px;
  color: #f26a2f;
  margin-bottom: 20px;
}

.footer-bottom {
  padding: 50px 0;
}

.footer-bottom strong {
  color: #000;
}

.footer-last {
  text-align: center;
  position: relative;
  width: 100%;
}

.footer-last::before {
  content: "";
  display: inline-block;
  width: 100%;
  background-color: #000;
  opacity: 10%;
  height: 1px;
  top: 60px;
  position: relative;
}

.footer-logo {
  display: inline-block;
  background-color: #ffeae1;
  padding: 0 40px;
  position: relative;
}

copyright {
  width: 100%;
  display: inline-block;
  padding: 15px 0;
}

ul.footer-social {
  padding: 0;
  display: flex;
  margin: 0;
}

ul.footer-social li {
  display: inline-block;
  margin-right: 6px;
}

ul.footer-social li a {
  background-color: #f26a2f;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
}

.contactpage-info {
  padding: 80px 0;
}

.adress-contctpag {
  padding: 45px;
  background-color: #fff;
  box-shadow: 0 5px 5px rgb(0 0 0 / 8%);
  border-radius: 10px;
}

.adress-contctpag h4 {
  font-size: 20px;
  color: #f26a2f;
  display: block;
}

.social-contac {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.social-contac ul {
  display: flex;
  align-items: center;
  margin: 0;
}

.social-contac ul li {
  display: inline-block;
  margin-right: 23px;
}

.adress-contctpag p {
  margin-bottom: 25px;
}

form input,
form select,
form textarea {
  height: 46px;
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 16px;
  font-family: "Quicksand";
  width: 100%;
  margin-bottom: 15px;
  outline: none;
}

input.btn.btn-fill {
  width: auto;
  min-width: 170px;
}

form textarea {
  height: 125px;
}

.contactpage-info h2 {
  margin-bottom: 30px;
}

.contact-form {
  padding-right: 40px;
}

.Our-Branches {
  padding-bottom: 50px;
}

.Our-Branches h2 {
  text-align: center;
}

.Our-Branches .nav-tabs .nav-link.active {
  color: #f26a2f;
  border: 2px solid #f26a2f;
  border-radius: 10px;
}

.Our-Branches .nav-tabs .nav-link {
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins";
  border: none;
  color: #111315;
  padding: 6px 16px;
}

.Our-Branches .nav-tabs {
  justify-content: center;
  border: none;
}

.Our-Branches .col-lg-4 {
  margin-top: 50px;
}

.branch-half {
  border: 1px solid #c2c2c2;
  border-radius: 10px;
  padding: 45px 20px 30px 20px;
  position: relative;
  height: 100%;
}

.branch-half h4 {
  background-color: #fff0e9;
  display: inline-block;
  font-size: 19px;
  padding: 8px 22px;
  border-radius: 30px;
  margin-bottom: 0;
  position: absolute;
  top: -20px;
}

span.contact-location {
  display: flex;
  align-items: flex-start;
  font-family: "Poppins";
  font-weight: 300;
  font-size: 15px;
}

span.contact-location img {
  margin-top: 4px;
}

.branch-half img {
  margin-right: 7px;
}

a.contact-call {
  font-size: 16px;
  font-weight: 600;
  color: #f26a2f;
  display: block;
  font-family: "Gilroy";
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
}

.showing-tour {
  font-size: 23px;
  font-weight: 700;
}

.toolbar select {
  border-radius: 5px;
  padding: 0 40px 0 20px;
  height: 45px;
  border: 1px solid #ddd;
  font-size: 16px;
  width: 285px;
}

/* EXTRA ADDED */
.css-b62m3t-container {
  width: 285px;
}

.mobile-toggle-filters {
  display: none;
}

.mobile-filter-close {
  border-bottom: 1px solid #ddd;
  padding: 10px 15px;
  font-size: 15px;
  color: #000;
  font-weight: 600;
  display: none;
}

.filter-title h4 {
  margin: 0;
}

.filter-title {
  background-color: #f26a2f;
  color: #fff;
  padding: 17px 20px;
}

.filter-items h4 {
  font-size: 16px;
  font-weight: 600;
  color: #202e3b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 10px 0;
}

.filter-items-main {
  padding: 0 20px;
}

.filter-items {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.side-filters {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 5px rgb(0 0 0 / 8%);
  overflow: hidden;
  position: sticky;
  top: 110px;
  margin-bottom: 50px;
}

ul.filter-items-ul {
  padding: 0;
  margin: 10px 0;
  display: inline-block;
  width: 100%;
}

ul.filter-items-ul li a {
  color: #202e3b;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
}

ul.filter-items-ul li a:hover,
.bedtype-filter a:hover,
ul.filter-items-ul li a.active-filtr {
  color: #f26a2f;
}

ul.filter-items-ul li a::before {
  content: "";
  border: 1px solid #ddd;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 2px;
}

ul.filter-items-ul li a.active-filtr::before,
ul.filter-items-ul li a:hover::before {
  /* background: #f26a2f url("./images/white-tick.svg"); */
  background-repeat: no-repeat;
  background-position: center center;
  border-color: #f26a2f;
}

ul.filter-items-ul li {
  display: inline-block;
  width: 100%;
}

ul.filter-items-ul li a i.fa.fa-star {
  color: #fac22e;
  font-size: 12px;
  margin-left: 2px;
}

.filter-items h4::after {
  content: "-";
}

.property-hotel-items {
  display: flex;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 5px rgb(0 0 0 / 8%);
  margin-bottom: 35px;
}

.property-hotel-pic {
  width: 388px;
  min-width: 388px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.property-hotel-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.property-hotel-data {
  width: calc(100% - 388px);
  display: flex;
  flex-direction: column;
}

.property-hotel-first {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  width: 100%;
  padding-right: 0;
}

.property-hotel-data-sec {
  background-color: #ffd2be;
  padding: 14px 30px;
  font-size: 15px;
  display: flex;
  align-items: center;
}

img.map-loc {
  width: 18px;
}

img.eye-loc {
  width: 24px;
  cursor: pointer;
}

.property-hotel-data-sec p {
  display: flex;
  align-items: center;
  gap: 11px;
  margin: 0;
  padding-left: 10px;
}

.property-hotel-data-sec p img {
  width: 11px;
}

ul.property-opti {
  padding: 0;
  display: flex;
  margin: 0;
}

ul.property-opti li {
  border: 1px solid #f26a2f;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 75px;
  margin-right: 10px;
}

ul.property-opti li span {
  width: 100%;
  text-align: center;
  background-color: #f26a2f;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 1px 5px;
}

ul.property-opti li img {
  margin: 9px 0;
  max-height: 30px;
}

.property-hotel-data-right {
  width: 250px;
  min-width: 250px;
  padding: 20px;
  text-align: center;
  border-left: 1px solid #ddd;
}

.property-hotel-data-midd {
  width: calc(100% - 250px);
  padding-right: 20px;
}

span.pricestaring {
  font-weight: 700;
}

span.pricestaring strong {
  font-size: 23px;
  color: #f26a2f;
}

.btn.round {
  border-radius: 50px !important;
}

a.btn-fill.btn-outline {
  background-color: #fff;
  color: #f26a2f;
}

.see-availbtn a.btn {
  margin: 3px 0;
}

.share-wishlist {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: #ddd;
  font-size: 21px;
  font-weight: 300;
  margin-top: 20px;
}

.share-wishlist img {
  height: 20px;
}

.days-desgin {
  border-radius: 50px;
  display: inline-block;
  border: 1px solid #f26a2f;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
}

.days-desgin strong {
  background-color: #f26a2f;
  color: #fff;
  padding: 3px 15px;
  display: inline-block;
}

.days-desgin span {
  padding: 0 15px;
  float: left;
  line-height: 30px;
}

.banner-leftside h2,
.banner-leftside h3 {
  font-family: "Kaushan Script", cursive;
  color: #fff;
  font-size: 70px;
  font-weight: normal;
}

.page-not-found-leftside h3,
.page-not-found-leftside h5 {
  color: #fff;
  font-weight: normal;
}

.banner-leftside h3 {
  font-size: 40px;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  line-height: normal;
  padding: 1px 0 10px 0;
}

.page-not-found-leftside h5 {
  line-height: normal;
  padding: 1px 0 10px 0;
  margin-top: 20px;
}

.banner-leftside p {
  font-size: 22px;
  color: #fff;
  font-weight: 700;
  margin-top: 20px;
}

.page-not-found-leftside p {
  color: #fff;
}

.banner-pic img {
  width: 576px !important;
  max-width: 90%;
}

.discover-pic {
  margin-bottom: 20px;
  /* EXTRA ADDED */
  cursor: pointer;
}

.discover-owl p {
  font-size: 18px;
}

.trail-slid-data {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  color: #fff;
  display: none;
  transition: 0.3s;
}

.flipster__item--current:hover .flipster__item__content .trail-slid-data {
  display: block;
}

.flipster__item--current:hover .flipster__item__content .trail-slid-pic img {
  opacity: 50% !important;
}

.trail-slid-pic {
  width: 100%;
  height: 100%;
  transition: 0.3s;
}

.trail-slid-pic img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 1 !important;
}

.destination-box-slid {
  background-color: #472b1e;
  color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.testimonial-box-slid {
  background-color: #472b1e;
  color: #fff;
  border-radius: 10px;
  overflow: hidden;
  /* height: 400px; */
}

.testimonial-box-slid p {
  overflow: auto;
  max-height: 145px;
  scrollbar-width: thin;
}

.testimonial-box-slid h4 {
  padding-top: 30px;
}

.testimonial-pic img {
  align-self: center;
  border-radius: 6px;
  margin: 0 10px;
}

.testimonial-data {
  padding: 25px;
  position: relative;
  height: 290px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
}

.destination-data {
  padding: 25px;
  position: relative;
}

.destination-data h4 {
  color: #f26a2f;
  margin: 0;
  /* EXTRA ADDED */
  cursor: pointer;
}

.destination-data small {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 10px 0;
  display: block;
}

.blog-pic {
  width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  vertical-align: middle;
}

.blog-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-box {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 10px;
}

.blog-contnt {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px 20px 20px 20px;
  color: #fff;
  transition: 0.2s;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%);
}

.showhove-blog {
  height: 0;
  transition: 0.2s;
  overflow: hidden;
}

.blog-box:hover .showhove-blog {
  height: auto;
  overflow: visible;
}

.blog-box:hover .blog-contnt {
  top: 0;
  background: rgb(242 106 47 / 90%);
  overflow: scroll;
  scrollbar-width: none;
}

span.timedate {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  border-left: 2px solid #fff;
  line-height: 14px;
  display: block;
  padding-left: 20px;
}

.blog-contnt h5 {
  margin: 15px 0;
}

.blog-box.blog-box-big .blog-contnt h5 {
  font-size: 30px;
}

a.anchorbtn {
  font-weight: 700;
  color: #fff;
  text-decoration: underline;
  /* EXTRA ADDED */
  cursor: pointer;
}

.paradise-box-row {
  display: flex;
  width: 100%;
  gap: 25px;
  margin-top: 30px;
}

.paradise-box-col {
  border-radius: 10px;
  box-shadow: 0 5px 5px rgb(0 0 0 / 19%);
  overflow: hidden;
  width: 33.333%;
}

.paradise-box-col img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.box-cinema-row {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 50px;
}

.box-v {
  width: 50%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0px 20px rgb(0 0 0 / 5%);
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 30px 50px;
}

.box-v h3 {
  font-size: 30px;
  margin-left: 90px;
}

.cinema-section {
  padding: 70px 0;
  background-color: #fff;
}

.dropmsg {
  max-width: 390px;
  color: #fff;
}

.mailus-at {
  position: relative;
  display: inline-block;
  padding-left: 65px;
  line-height: normal;
  margin-top: 20px;
}

span.mailtic {
  width: 54px;
  height: 54px;
  text-align: center;
  line-height: 50px;
  background-color: #f26a2f;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.mailus-at h5 {
  margin: 0;
}

.mailus-at a {
  font-size: 18px;
  color: #fff;
  text-decoration: none;
}

.dropmsg p {
  font-size: 20px;
}

.tellabout-section {
  padding: 80px 0;
}

.tellabout-section .row {
  align-items: center;
}

.tellabout-section form input,
.tellabout-section form select,
.tellabout-section form textarea {
  opacity: 70%;
  color: #000;
}

.tellabout-section form input.btn.btn-fill {
  opacity: 1;
}

.quote-ico img {
  width: auto !important;
  height: auto !important;
}

.quote-ico {
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 50px;
  background-color: #f26a2f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 10px;
}

.testimonial-section .destination-data small {
  padding-top: 0;
}

.testimonial-section .owl-theme .owl-nav {
  text-align: right;
}

.testimonial-section .row {
  align-items: center;
}

.testimon-col-left {
  padding-left: 60px;
}

div.owl-carousel div.owl-nav button,
.flipster__button {
  background: url("./assets/images/arrow-rigt.png") !important;
  background-repeat: no-repeat !important;
  background-size: 27px !important;
  width: 50px;
  height: 30px;
  background-position: center center !important;
  font-size: 0 !important;
}

div.owl-carousel div.owl-nav button.owl-prev,
.flipster__button.flipster__button--prev {
  background: url("./assets/images/arrow-left.png") !important;
  background-repeat: no-repeat !important;
  background-size: 27px !important;
  background-position: center center !important;
}

.flipster__button {
  top: auto;
}

.discouver-section h2 {
  margin-bottom: 30px;
}

.discover-pic {
  border-radius: 10px;
  overflow: hidden;
}

.discouver-section h4 {
  margin-bottom: 3px;
}

.arrow-top-right .owl-nav {
  position: absolute;
  right: 0;
  top: -81px;
}

.owl-carousel .owl-nav button.owl-next {
  margin-right: 0;
}

.tabsameclss {
  width: 250px;
  display: flex;
  flex-direction: column;
}

.tabsameclss li.nav-item button {
  width: 100%;
  display: flex;
  text-align: left;
  padding: 12px 20px;
  border: 2px solid #f26a2f !important;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 700;
  color: #fff;
  align-items: center;
  gap: 20px;
}

.tabsameclss li.nav-item:last-child button {
  margin-bottom: 0px;
}

.tabsameclss li.nav-item button {
  background: url("./assets/images/jan-bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
}

.tabsameclss li.nav-item button img {
  filter: brightness(10.5);
}

.festival-season div#myTabContent {
  width: calc(100% - 500px);
  padding: 0 20px;
  height: auto;
}

.festival-season .tab-content .tab-pane {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.festival-season .tab-content .tab-pane .slick-list,
.festival-season .tab-content .tab-pane .slick-list .slick-track,
.festival-season .tab-content .tab-pane .slick-slide div:first-child,
.festival-season .tab-content .tab-pane .item {
  height: 100% !important;
}

.tabsameclss li.nav-item button.active {
  color: #f26a2f;
  background: #fff !important;
}

.tabsameclss li.nav-item button.active img {
  filter: none;
}

.festival-season {
  background-color: #eef4fa;
  padding: 50px 0;
}

ul#myTab {
  border: 0;
  margin-top: 30px;
}

span.date-bg {
  background-color: #f26a2f;
  border-radius: 50px;
  padding: 3px 26px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
  display: inline-block;
}

.tabbing-pic {
  position: relative;
}

.tabbing-pic img {
  height: 100%;
  object-fit: cover;
}

.tabbingpcontt h5 {
  margin-bottom: 0px;
  max-width: calc(100% - 110px);
}

.tabbingpcontt {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%);
  color: #fff;
}

.tabbing-owl .owl-nav {
  position: absolute;
  right: 10px;
  bottom: 10px;
  filter: invert(1);
  margin: 0;
}

.festival-season h2 {
  margin-bottom: 0;
}

.full-section h2 {
  margin-bottom: 30px;
}

.tabbing-owl .owl-stage {
  display: flex;
  height: 100%;
}

.owl-carousel.tabbing-owl,
.owl-carousel.tabbing-owl .owl-stage-outer,
.tabbing-owl .owl-stage .item,
.tabbing-box-slid,
.tabbing-pic {
  height: 100%;
}

.blog-section .bg-sections img {
  object-position: bottom;
}

.trail-slid-pic {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background-color: #000;
}

button.flipster__button {
  bottom: 0;
  opacity: 1;
}

button.flipster__button.flipster__button--next {
  right: calc(50% - 55px);
}

button.flipster__button.flipster__button--prev {
  left: calc(50% - 55px);
}

div#carousel {
  overflow-y: visible;
  padding: 30px 0 60px 0;
}

.vie-allbtn {
  text-align: center;
  padding-top: 20px;
}

.tour-single header {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
}

.tour-single .bredcrum-gray {
  padding-top: 110px;
}

.bredcrum-gray {
  background-color: #f2f2f2;
  text-align: left;
  padding: 10px 0;
}

.bredcrum-gray .breadrm {
  justify-content: flex-start;
}

.bredcrum-gray .breadrm a {
  color: #000;
}

.detiltitle-under {
  display: flex;
  align-items: center;
  gap: 20px;
}

.detiltitle-under .share-wishlist {
  margin: 0;
}

/* Extra Changes ADDED */
.tour-single {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.bredcrum-gray {
  background-color: #f2f2f2;
  text-align: left;
  padding: 10px 0;
}

.bredcrum-gray .breadrm {
  justify-content: flex-start;
}

.bredcrum-gray .breadrm a {
  color: #000;
}

.detiltitle-under {
  display: flex;
  align-items: center;
  gap: 20px;
}

.detiltitle-under .share-wishlist {
  margin: 0;
}

.tour-single {
  background-color: #fff;
}

.tour-single button.menu-toggle span {
  background-color: #f26a2f;
}

.tour-detail-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
  width: 100%;
}

.tour-detail-price {
  text-align: right;
  line-height: normal;
}

.package-higlite-inclued {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0 20px 0;
}

.package-higlite ul {
  padding: 0;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.package-higlite ul li {
  display: flex;
  align-items: center;
  width: 50%;
  padding-left: 28px;
  position: relative;
  margin-bottom: 5px;
  background: url("./assets/images/tick-black.png");
  background-position: left center;
  background-repeat: no-repeat;
}

.parckage-cities p img {
  max-width: 18px;
}

.parckage-cities p {
  display: flex;
  align-items: center;
  gap: 14px;
}

.tour-page-tabing {
  /* border: 1px solid #ebebeb; */
  width: 100%;
  margin-top: 60px;
  margin-bottom: 50px;
  display: flex;
}

.tour-page-tabing ul.nav-tabs.nav-tabs-packmain {
  flex-direction: column;
  background-color: #eaf8fb;
  width: 250px;
  min-width: 250px;
  border: 0 !important;
  margin: 0 !important;
}

.tour-page-tabing .tab-content.tab-content-main {
  padding: 30px 50px;
  width: calc(100% - 250px);
}

.tour-page-tabing ul.nav-tabs.nav-tabs-packmain li button {
  width: 100%;
  text-align: left;
  color: #000;
  border: 0;
  padding: 20px 20px;
  border-left: 5px solid transparent;
  border-radius: 0;
}

.tour-page-tabing ul.nav-tabs.nav-tabs-packmain li button.active {
  border-color: #f26a2f;
  color: #f26a2f;
  font-weight: 600;
}

.Overview-meal-visa {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.Overview-mealbox {
  width: 50%;
  border: 1px solid #f26a2f;
  border-radius: 5px;
  overflow: hidden;
}

.Overview-mealbox h6 {
  background-color: #f26a2f;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 20px;
  margin: 0;
}

.mealbox-data {
  padding: 20px;
}

.big-tou-pic {
  width: 100%;
  position: relative;
  display: inline-block;
  border-radius: 10px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.big-tou-pic img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.packages-sidebar-box-title {
  display: inline-block;
  width: 100%;
  padding: 13px 20px;
  background-color: #000;
  color: #fff;
}

.packages-sidebar-box-title h5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px;
}

.packages-sidebar-box-data {
  padding: 20px;
}

.packages-sidebar-box-data.nopadding {
  padding: 0;
}

.form-data {
  padding: 20px;
}

.super-deal-form {
  background-color: #f5f5f5;
  text-align: center;
  width: 100%;
  padding: 14px 20px;
}

.super-deal-form p {
  margin: 0px;
}

.enwureyform {
  padding: 20px;
}

.enwureyform a.btn {
  width: 100%;
  line-height: 43px;
}

.packages-sidebar-box {
  border-radius: 10px;
  border: 1px solid #ebebeb;
  overflow: hidden;
  width: 100%;
  margin-bottom: 30px;
}

.packages-fromfield label {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 2px;
}

.packages-fromfield {
  display: inline-block;
  width: 100%;
  margin-bottom: 4px;
}

.packages-fromfield.half-60 {
  width: 55%;
}

.packages-fromfield.half-40 {
  float: right;
  width: 40%;
}

input.map-destina {
  background: #fff url("./assets/images/map-gray.png");
  background-repeat: no-repeat;
  background-position: 93%;
}

.packages-fromfield select {
  height: 46px;
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 16px;
  font-family: "Quicksand";
  width: 100%;
  margin-bottom: 15px;
  outline: none;
}

.recent-items {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.recentpic {
  width: 93px;
  min-width: 93px;
  border-radius: 5px;
  overflow: hidden;
}

.recentpic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recent-items h6 {
  padding: 0 0 0 17px;
  font-size: 15px;
  margin: 0;
  font-weight: 600;
  line-height: normal;
}

.packages-sidebar-box-data ul {
  padding: 0;
  margin: 0;
}

.packages-sidebar-box-data ul li {
  display: inline-block;
  width: 100%;
  padding-left: 25px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  background: url("./assets/images/dble-arro.png");
  background-repeat: no-repeat;
  background-position: left 3px;
}

.box-blue .packages-sidebar-box-title {
  background-color: #f26a2f;
}

.packages-side-col {
  width: 387px;
}

.packages-left-col {
  width: calc(100% - 387px);
}

.nav-tabs-flight {
  border: 0;
  margin-bottom: 30px;
}

.nav-tabs-flight li button {
  background-color: #fff;
  border: 1px solid #f26a2f !important;
  border-radius: 50px !important;
  font-weight: 600;
  color: #f26a2f !important;
  padding: 5px 30px;
  margin-right: 20px;
}

.nav-tabs-flight li button.active {
  background-color: #f26a2f !important;
  color: #fff !important;
}

.flight-details-box {
  display: inline-block;
  width: 100%;
  box-shadow: 0 0 10px rgb(0 0 0 / 7%);
  border-radius: 10px;
  margin-bottom: 40px;
}

.flight-details-box h5 {
  margin: 0;
  background-color: #e3fbff;
  padding: 12px 30px;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.flight-details-box-in {
  display: flex;
  padding: 0 20px;
}

.flight-details-box-in {
  display: flex;
  padding: 20px 10px;
  width: 100%;
}

.flight-left,
.flight-mid {
  width: 30%;
  font-size: 14px;
  line-height: normal;
  color: #000;
  text-align: right;
  padding: 0 15px;
}

.flight-details-box-in h6 {
  color: #f26a2f;
  font-size: 18px;
  margin: 0 0 3px 0;
}

.flight-right {
  border-left: 1px solid #ddd;
  text-align: center;
  padding: 0 15px;
}

.hr-line {
  width: 10%;
  margin: auto auto;
  border-bottom: 1px dashed #000;
}

.flight-details-box-in p {
  margin: 0;
}

.flight-details-box-in strong {
  font-weight: 600;
}

.middle-line {
  border-bottom: 1px dashed #000;
}

.middle-line {
  border-bottom: 1px dashed #ddd;
  max-width: calc(100% - 60px);
  margin: 0 auto;
}

h5.return img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.conclusiton-box {
  width: 100%;
  border: 1px solid #107d01;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
}

.conclusiton-box h5 {
  background-color: #107d01;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  margin: 0;
  font-size: 17px;
}

.conclusiton-box-data {
  padding: 20px;
}

.conclusiton-box-data ul li {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
  padding-left: 20px;
}

.conclusiton-box-data ul li::before {
  content: "";
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #107d01;
  position: absolute;
  left: 0;
  top: 8px;
}

.conclusiton-box.red-box {
  border-color: #cc3d40;
}

.conclusiton-box.red-box h5,
.red-box .conclusiton-box-data ul li::before {
  background-color: #cc3d40;
}

.accordion {
  border: 0 !important;
  width: 100%;
}

.accordion .accordion-item {
  border: 0;
  padding: 0;
  border-radius: 0;
  position: relative;
}

.accordion .accordion-date-item {
  border: 0;
  padding: 0;
  border-radius: 0;
  position: relative;
}

.accordion-button {
  background-color: transparent !important;
  border: 0 !important;
  display: flex;
  align-items: flex-start;
  width: 100%;
  color: #000 !important;
  font-size: 17px;
  box-shadow: none !important;
  padding: 0;
  min-height: 65px;
}

.accordion-date-button {
  background-color: transparent !important;
  border: 0 !important;
  display: flex;
  align-items: center;
  width: 100%;
  color: #000 !important;
  font-size: 17px;
  box-shadow: none !important;
  padding: 5px;
  min-height: 40px;
}

.accordion .accordion-item::before {
  content: "";
  border-left: 1px dashed #f26a2f;
  position: absolute;
  left: 15%;
  top: 0;
  bottom: 0;
  margin-left: 7px;
}

.accordion .accordion-date-item::before {
  content: "";
  position: absolute;
  left: 30%;
  top: 0;
  bottom: 0;
  margin-left: 7px;
}

.days-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: normal;
  width: 15%;
}

.month-header {
  background-color: #f26a2f !important;
  margin: 10px 0;
}

.month-title {
  width: 100%;
}

.days-left small {
  font-size: 13px;
  font-weight: 500;
  margin-top: 7px;
}

.accordion-body {
  width: calc(100% - 15%);
  margin-left: auto;
  padding-left: 45px;
}

.accordion-date-body {
  width: calc(100% - 30%);
  padding: 0;
}

.accordion-date-list {
  list-style: auto;
}

.accordion-date {
  padding: 5px;
}

.days-left span {
  color: #000;
  font-weight: 400;
}

.days-left span strong {
  margin-right: 15px;
}

.accordion-button h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  width: 75%;
  max-width: 85%;
  display: flex;
  align-items: center;
}

.accordion-date-button h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  max-width: 455px;
  display: flex;
  align-items: center;
}

.accordion-button h6 img {
  width: 16px;
  margin-right: 20px;
  background-color: #fff;
  padding: 2px 0;
}

.accordion-date-button h6 img {
  width: 16px;
  margin-right: 20px;
  background-color: #fff;
  padding: 2px 0;
}

.accordion-button::after {
  content: "+";
  background: #fff;
  border: 1px solid #000;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  text-align: center;
  font-size: 23px;
  line-height: 31px;
  margin-right: 10px;
}

.accordion-date-button::after {
  content: "+";
  background: #fff;
  border: 1px solid #000;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  text-align: center;
  font-size: 23px;
  line-height: 31px;
}

.accordion .accordion-item:last-child::before {
  display: none;
}

.accordion .accordion-date-item:last-child::before {
  display: none;
}

.accordion-button:not(.collapsed)::after {
  background: #fff !important;
  content: "-";
}

.accordion-date-button:not(.collapsed)::after {
  background: #fff !important;
  content: "-";
}

.stay-tb {
  background-color: #eef4f5;
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.stay-tb ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.stay-tb ul li {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  font-weight: 600;
  font-size: 15px;
}

.stay-tb ul li img {
  mix-blend-mode: multiply;
}

.itineraty-maps {
  display: inline-block;
  width: 100%;
  padding: 20px 0;
  margin-bottom: 30px;
}

.simlar-owl .item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  font-size: 13px;
}

/* REMOVE AFTER */
/* .simlar-div {
  padding: 0 12px;
} */
.simlar-div .item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* width: 100%; */
  border-radius: 10px;
  overflow: hidden;
  font-size: 13px;
  margin: 0 12px;
}

.indian-gateways-owl .item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  font-size: 13px;
  margin: 0 10px;
}

/* REMOVE AFTER */
.indian-gateways-div .item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /* width: 100%; */
  border-radius: 10px;
  overflow: hidden;
  font-size: 13px;
}

.similarproduct-pic {
  display: inline-block;
  width: 100%;
  height: 254px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.similarproduct-pic img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.similarproduct-pic .days-desgin {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: #fff;
}

.simlar-owl .item img {
  width: auto;
  height: auto;
}

.indian-gateways-owl .item img {
  width: auto;
  height: auto;
}

/* REMOVE AFTER */
.indian-gateways-div .item img {
  width: auto;
  height: auto;
}

.similter-city {
  display: flex;
  width: 100%;
  padding: 10px 0;
  align-items: flex-start;
}

.similter-city img.map-loc {
  width: 15px;
  margin-right: 10px;
}

.similter-city p {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 0px;
  align-items: center;
  font-size: 16px;
}

.similter-city p img {
  width: 14px !important;
  height: auto !important;
}

.price-similar span {
  font-size: 23px;
  font-weight: 700;
  color: #f26a2f;
  display: block;
}

.price-similar strong {
  font-size: 13px;
}

.similarproduct-data ul.property-opti li {
  border: 0;
  margin: 0;
  width: 25%;
}

.similarproduct-data ul.property-opti li span {
  background-color: transparent;
  color: #000;
  padding: 0;
}

.similarproduct-data ul.property-opti li img {
  margin-bottom: 5px;
}

.similarproduct-data ul.property-opti {
  gap: 0px;
}

.similarproduct-data {
  padding: 10px 20px 20px 20px;
  line-height: normal;
}

/* Extra Added */
.hoverable-title:hover {
  cursor: pointer;
  color: #f26a2f;
}

.cursor-pointer {
  cursor: pointer;
}

.price-similar {
  padding: 10px 0;
}

.simlar-owl .owl-stage-outer {
  padding: 15px 0;
}

.owl-carousel.tabbing-owl .owl-stage-outer {
  height: 440px !important;
}

.indian-gateways-owl .owl-stage-outer {
  padding: 15px 0;
}

.similer-packages {
  padding: 30px 0 70px 0;
}

/**/
.navigation-main {
  background-color: #f7f7f7;
  position: fixed;
  top: 0;
  left: -100%;
  bottom: 0;
  height: 100%;
  width: 100%;
  max-width: 800px;
  z-index: 99;
  transition: 0.3s;
}

.close-btn-menu {
  display: inline-block;
  font-size: 35px;
  font-weight: 300;
  transform: rotate(45deg);
  /* CHANGE BECAUSE THE PROBLEM */
  /* float: right; */
  padding: 10px 10px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 20px;
}

.close-btn-menu::before {
  content: "+";
}

.navigation-main.menu-active {
  left: 0;
}

.menu-main {
  display: flex;
  height: 100%;
  width: 100%;
}

.menu-main ul.nav-menu {
  width: 50%;
  padding: 50px;
  margin: 0;
  background-color: #ff8c5a;
  /* background-color: #f26a2f; */
  color: #fff;
}

.submenu-nav {
  width: 50%;
  padding: 50px;
}

ul.kashmir-submenu {
  padding-left: 20px !important;
}

.menu-main ul li {
  display: inline-block;
  width: 100%;
  margin: 5px 0;
}

.submenu-nav ul {
  padding: 0;
  margin: 0;
}

ul.nav-menu li a {
  color: #fff;
}

.menu-main ul li a {
  font-size: 17px;
  font-weight: 400;
  display: inline-block;
  width: 100%;
}

.submenu-nav h5 {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.submenu-nav {
  max-height: 100%;
  overflow-y: auto;
}

.close-submenu {
  display: none;
}

.ui-slider-horizontal {
  height: 4px;
}

div#slider-range {
  background-color: #e4f0fc;
  border: 0;
  max-width: calc(100% - 20px);
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
}

.ui-slider-horizontal .ui-slider-range {
  background-color: #f26a2f;
}

.ui-slider-horizontal .ui-slider-handle {
  width: 20px;
  height: 20px;
  border: 0px;
  background-color: #f26a2f;
  border-radius: 50%;
  top: -8px !important;
  opacity: 1 !important;
  box-shadow: 0 0 10px rgb(46 186 211 / 63%);
}

input.price-range-field {
  border: 0;
  border-radius: 5px;
  height: 39px;
  padding: 0 0;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  display: block;
  width: 96px;
  /* Extra Added for Styling */
  margin: 0;
}

/* Extra Added for Styling */
#max_price:focus,
#min_price:focus {
  border: 2px solid black;
}

.under-range-bar-price {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  font-size: 12px;
}

.max-prce input.price-range-field {
  text-align: right !important;
  padding: 0;
}

.paginations {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}

.paginations .page-number {
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 5px 5px rgb(0 0 0 / 10%);
  text-align: center;
  line-height: 27px;
  font-weight: 600;
  margin: 0 7px;
  font-size: 15px;
}

.paginations img {
  width: 27px;
  margin-left: 10px;
}

.paginations a.page-number.current-page {
  background-color: #f26a2f;
  color: #fff;
}

/* EXTRA ADDED */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}

.pagination li {
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 5px 5px rgb(0 0 0 / 10%);
  text-align: center;
  line-height: 27px;
  font-weight: 600;
  margin: 0 7px;
  font-size: 15px;
  cursor: pointer;
}

.pagination li:hover {
  background-color: #f26a2f;
  color: #fff;
}

.pagination li.active {
  background-color: #f26a2f;
  color: #fff;
}

.city-box-inner {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 5px 5px rgb(0 0 0 / 19%);
  width: 100%;
  padding-bottom: 280px;
}

.explore-word-owl {
  right: -50px;
  margin-top: -250px;
  min-width: 52vw;
  float: right;
}

.explore-word-slick {
  right: -50px;
  margin-top: -250px;
  min-width: 52vw;
  float: right;
  width: 100%;
  z-index: 1;
}

.explore-word-slick .item {
  cursor: pointer;
}

.explore-word-owl .owl-nav {
  text-align: right;
  padding-right: 70px;
}

.row.exlore-row {
  position: relative;
}

.exlore-wold-pic {
  height: 165px;
  border-radius: 10px;
  box-shadow: 0 5px 5px rgb(0 0 0 / 19%);
  overflow: hidden;
  margin: 12px 7px;
  position: relative;
  background-color: #000;
}

.exlore-wold-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cityBox {
  padding-right: 100px;
  position: relative;
}

.city-box-inner h4 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.highcharts-legend,
.highcharts-exporting-group,
.highcharts-credits,
.highcharts-title {
  display: none;
}

#container {
  height: 500px;
  min-width: 310px;
  max-width: 800px;
  margin: 0 auto;
}

.loading {
  margin-top: 10em;
  text-align: center;
  color: gray;
}

.exploreindia-section ul.nav {
  justify-content: flex-end;
  margin-bottom: 0;
}

.exploreindia-section ul.nav li button {
  border-radius: 50px;
  border: 1px solid #202e3b !important;
  color: #202e3b;
  font-size: 16px;
  font-weight: 600;
  padding: 4px 20px;
  margin-left: 10px;
}

.exploreindia-section ul.nav li button.active {
  background-color: #f26a2f;
  border-color: #f26a2f !important;
  color: #fff;
}

.cusomize-holiday-tabbing h2 {
  text-align: center;
  margin-bottom: 30px;
}

.cusomize-holiday .similer-packages {
  margin: 15px 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0;
}

.cusomize-holiday-tabbing ul#pills-tab {
  justify-content: center;
  margin-bottom: 20px !important;
}

.cusomize-holiday-tabbing .nav-pills .nav-link {
  font-size: 18px;
  font-weight: 500;
  color: #111315;
  padding: 19px 18px;
  border-radius: 15px;
}

.cusomize-holiday-tabbing .nav-pills .nav-link.active {
  background-color: #f26a2f;
  color: #fff;
  position: relative;
}

.cusomize-holiday-tabbing .nav-pills .nav-link.active:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid #f26a2f;
  width: 0;
  margin: 0 auto;
}

.banner-img img {
  min-height: 280px;
  object-fit: cover;
  width: 100%;
}

.banner-text h1 {
  color: #fff;
  text-transform: uppercase;
  font-size: 70px;
  font-weight: 700;
}

.banner-section {
  position: relative;
}

.banner-text {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.cusomize-holiday-tabbing,
.Personalized-package-section {
  padding: 50px 0px;
}

.Personalized-package {
  padding: 10px 0px;
}

.filter-checkbox-label {
  cursor: pointer;
}

.filter-checkbox-label:hover {
  color: #f26a2f !important;
}

.filter-checkbox {
  cursor: pointer;
  margin-right: 1rem;
  margin-bottom: 0;
  height: 15px !important;
  width: 15px !important;
}

.filter-checkbox :hover {
  color: #f26a2f !important;
}

/* Extra CSS Added for range slider */

.error-border {
  border: 2px solid red !important;
}

#range-slider-yellow {
  height: 4px;
  margin-top: 30px;
}

#range-slider-yellow input[type="range"] {
  border: none;
}

#range-slider-yellow .range-slider__range {
  background: #f26a2f;
  transition: height 0.3s;
}

#range-slider-yellow .range-slider__thumb {
  background: #f26a2f;
  height: 20px;
  width: 20px;
  transition: transform 0.3s;
}

#range-slider-yellow .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.25);
}

#range-slider-yellow .range-slider__range[data-active] {
  height: 10px;
}

.flip-items .item {
  /* EXTRA ADDED */
  cursor: pointer;
}

/* REMOVE AFTER */
.flip-items-div .item {
  cursor: pointer;
  width: 632px;
  height: 417px;
}

/* .flip-items-div .item:hover .trail-slid-data {
  display: block;
}
.flip-items-div .item:hover .trail-slid-pic img {
  opacity: 50% !important;
} */

.flip-items-div .item:hover .trail-slid-data,
.flip-items-div .item.touched .trail-slid-data {
  display: block;
}

.flip-items-div .item:hover .trail-slid-pic img,
.flip-items-div .item.touched .trail-slid-pic img {
  opacity: 50% !important;
}

.explore-word-slick .item:hover .trail-slid-data,
.explore-word-slick .item.touched .trail-slid-data {
  display: block;
}

.explore-word-slick .item:hover .exlore-wold-pic img,
.explore-word-slick .item.touched .exlore-wold-pic img {
  opacity: 50% !important;
}

.flip-items .owl-item :hover .trail-slid-data {
  display: block;
}

.flip-items .owl-item :hover .trail-slid-pic img {
  opacity: 50% !important;
}

.blog-post {
  padding: 50px 0px;
}

p.blog-post-time img {
  margin-right: 10px;
}

p.blog-post-time span {
  margin-right: 20px;
}

p.blog-post-time {
  margin-top: 20px;
  margin-bottom: 5px;
}

.blog-post-column {
  margin-bottom: 30px;
}

.blog-post-pic img:hover {
  opacity: 0.5;
}

/* .blog-post-pic:hover {
  background-color: #f97150;
} */

.pegination {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.pegination span:hover {
  background-color: #f97150;
}

.pegination span:hover a {
  color: #fff;
}

span.paginatio-next {
  background-image: url("./assets/images/pegination.png");
  background-repeat: no-repeat;
  background-position: center;
}

span.paginatio-next:hover {
  background-image: url("./assets/images/pagination-white.png");
}

.pegination span {
  background-color: #e6e6e6;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.blog-post-right ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.blog-post-right li {
  list-style: none;
  width: 33.33%;
  padding: 5px;
  height: 100px;
}

.blog-post-right li img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: 0.2s;
  object-fit: cover;
}

.blog-post-right li img:hover {
  transform: scale(1.1);
}

.blog-detail-right li {
  margin-right: 10px;
  border: 1px solid #e1e1e1;
  padding: 10px 18px;
  margin-bottom: 10px;
  list-style: none;
  border-radius: 5px;
}

.blog-detail-right ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.blog-detail-right li a {
  font-weight: 500;
}

.blog-detail p {
  color: #8c8c8c;
}

.blog-detail-right h6 {
  margin-bottom: 20px;
  margin-top: 30px;
}

p.blog-right-border {
  border-bottom: 1px solid #dadada;
  padding-bottom: 20px;
}

.blog-detail-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.blog-detail-img .col-md-6.col-sm-12 {
  margin-bottom: 30px;
}

.blog-detail {
  padding: 50px 0px;
}

.blog-detail p.blog-post-time img {
  margin-bottom: 0px;
}

.blog-detail p.blog-post-time {
  margin-bottom: 15px;
}

.modal-header {
  background: #f26a2f;
  color: #fff;
  padding: 4px 30px;
  border-radius: 0;
}

.modal-header button.btn-close {
  background-color: #000;
  width: 20px;
  height: 20px;
  border-radius: 0;
  opacity: 1;
}

.modal-left ul {
  padding-left: 0;
}

.modal-left li {
  list-style: none;
  padding-bottom: 5px;
}

.modal-left p {
  display: flex;
  align-items: center;
}

.modal-left span {
  border: 1px solid #f26a2f;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.modal-call span {
  display: block;
  font-size: 17px;
  font-weight: 500;
}

.modal-call span a {
  margin-left: 10px;
}

.modal-form select,
.modal-form input {
  width: 100%;
  padding: 13px 30px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
}

.modal-body {
  padding: 15px;
}

.modal-footer {
  padding: 12px 15px;
}

.modal-footer button.btn {
  background: #f26a2f;
  border-color: #f26a2f;
}

.modal-dialog button.btn.btn-primary {
  margin-right: 10px;
}

button.btn-close {
  background-image: url("./assets/images/remove.png");
  background-repeat: no-repeat;
  background-position: center;
}

.modal-dialog {
  max-width: 850px;
  background: #fff;
}

.tour-list-detail {
  padding-top: 50px;
  padding-bottom: 50px;
}

.tour-list-detail a {
  font-weight: 700;
}

.kashmir-list-detail {
  max-height: 200px;
}

.kashmir-list-detail.active {
  max-height: none;
}

.kashmir-list-detail .kashmir-list-detail-body {
  max-height: 200px;
  overflow: hidden;
}

.kashmir-list-detail.active .kashmir-list-detail-body {
  max-height: none;
}

.kashmir-list-detail.active a.action-more {
  display: none;
}

.kashmir-list-detail.active a.action-less {
  opacity: 1;
}

.kashmir-list-detail a.action-less {
  opacity: 0;
}

.kashmir-list-detail a.read-more-action {
  color: #f26a2f;
}

/* NEW ADDED  */
.gateway-detail .conclusiton-box {
  height: 100%;
}

.gateway-detail {
  padding: 50px 0px;
}

.gateway-detail .row {
  padding: 0px;
}

.gateway-detail-pic img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.gateway-detail-pic {
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

/* EXTRA ADDED */

.discover-slick .slick-list {
  padding: 0 15px;
}

.discover-slick .slick-list .slick-track .slick-slide {
  padding: 0 30px;
  /* width: 336px !important;
  margin: 0 50px 0 10px; */
}

.flip-items-slick .slick-list {
  padding: 0 15px;
}

/* .flip-items-slick .slick-list .slick-track .slick-slide {
  padding: 0 15px;
} */

.testimonial-slick .slick-list {
  border-radius: 10px;
}

.tabbing-slick {
  height: 100%;
}

.tabbing-slick .item {
  height: 100%;
}

.discover {
  padding: 0 15px;
}

.discover .item {
  padding: 0 30px;
}

.loading-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #f26a2f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.no-data-found {
  text-align: center;
  padding: 20px;
  background-color: #f8f8f8;
  color: #888;
}

.thankyou-wrapper {
  width: 100%;
  height: auto;
  margin: auto;
  background: #fff;
  padding: 10px 0px 50px;
}

.thankyou-wrapper h1 {
  text-align: center;
  color: #333;
  padding: 0px 10px 10px;
  margin-top: 0;
  margin-bottom: 0;
}

.thankyou-wrapper h1 img {
  margin: 0 auto;
}

.thankyou-wrapper p {
  text-align: center;
  color: #333;
  padding: 5px 10px 10px;
  font-size: 25px;
  margin-bottom: 0;
}

.thankyou-wrapper a {
  text-align: center;
  color: #fff;
  display: block;
  text-decoration: none;
  border-radius: 10px;
  width: 250px;
  background: #f26a2f;
  margin: 10px auto 0px;
  padding: 15px 20px 15px;
  border: 1px solid #f26a2f;
}

.thankyou-wrapper a:hover {
  background: #fff;
  color: #f26a2f;
}

/* this is for trail carosoul from 3d */

.css-doq0dk>div:nth-child(1) {
  opacity: 1 !important;
}

.css-doq0dk>div:nth-child(2) {
  left: 37% !important;
  opacity: 1 !important;
}

.css-doq0dk>div:nth-child(5) {
  opacity: 1 !important;
}

.css-doq0dk>div:nth-child(4) {
  left: 63% !important;
  opacity: 1 !important;
}

.css-doq0dk>div:nth-child(3) .item {
  cursor: default;
}

.css-doq0dk>div:nth-child(1) .item,
.css-doq0dk>div:nth-child(5) .item {
  height: 632px;
}

.css-doq0dk>div:nth-child(2) .item,
.css-doq0dk>div:nth-child(4) .item {
  height: 505px;
}

.css-doq0dk>div:nth-child(1) .item .trail-slid-pic,
.css-doq0dk>div:nth-child(5) .item .trail-slid-pic {
  height: 632px;
}

.css-doq0dk>div:nth-child(2) .item .trail-slid-pic,
.css-doq0dk>div:nth-child(4) .item .trail-slid-pic {
  height: 505px;
}

.css-doq0dk>div:nth-child(1) .item .trail-slid-pic img,
.css-doq0dk>div:nth-child(2) .item .trail-slid-pic img,
.css-doq0dk>div:nth-child(5) .item .trail-slid-pic img,
.css-doq0dk>div:nth-child(4) .item .trail-slid-pic img {
  object-fit: cover;
}

.css-1qzevvg>img:nth-child(1) {
  background-image: url("./assets//images//right-arrow.png") !important;
}

/* this is for set carosoul's arrow's fixing */

.discover-slick .arrow {
  position: absolute;
  right: 2rem;
  top: -65px;
  cursor: pointer;
  z-index: 10;
}

.discover-slick .prev {
  margin-right: 5rem;
}

.explore-word-slick .arrow {
  position: absolute;
  right: 5rem;
  bottom: -25px;
  cursor: pointer;
  z-index: 10;
}

.explore-word-slick .prev {
  margin-right: 3rem;
}

.under-construction-message {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  color: red;
  padding: 20px;
}

.under-construction-message>* {
  display: inline-block;
  animation: moveTrail 15s infinite linear;
}

.back-to-home-btn {
  text-align: center;
  color: #fff;
  display: block;
  text-decoration: none;
  border-radius: 10px;
  width: 250px;
  background: #f26a2f;
  padding: 15px 20px 15px;
  border: 1px solid #f26a2f;
}

.back-to-home-btn:hover {
  background: #fff;
  color: #f26a2f;
}

.whatsapp-inquiry-btns {
  position: fixed;
  right: 2px;
  bottom: 15%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 254px;
  width: 52px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.whatsapp-button {
  height: 50px;
  border: solid 3px #64b161;
  box-shadow: 0 3px 3px #332;
  background: #64b161;
  width: 150px;
  line-height: 38px;
  -webkit-transform: rotate(90deg);
  font-weight: 600;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  border-radius: 0;
}

.whatsapp-button a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #fff;
}

.whatsapp-button a:hover {
  color: #f26a2f !important;
}

.inquiry-button {
  height: 50px;
  border: solid 3px #146656;
  box-shadow: 0 1px 3px #332;
  background: #146656;
  width: 160px;
  line-height: 38px;
  -webkit-transform: rotate(90deg);
  font-weight: 600;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  border-radius: 0;
}

.inquiry-button a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #fff;
}

.inquiry-button a:hover {
  color: #f26a2f;
}

.mobile-footer-btns {
  margin: 0;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  padding: 0;
}

.mobile-footer-btns li {
  width: 50%;
  float: left;
  list-style-type: none;
  margin-right: 0;
  text-align: center;
  /* background: green !important; */
}

.mobile-footer-btns li a {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  height: 40px;
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.mobile-inquiry-btn {
  border: solid 3px #146656;
  background: #146656;
  display: none;
}

.mobile-whatsapp-btn {
  border: solid 3px #64b161;
  background: #64b161;
  display: none;
}

.address-page-card {
  background: rgb(71, 43, 30);
  color: white;
  padding: 20px;
  border-radius: 10px;
  height: 100%;
}

.address-page-card:hover {
  transform: scale(1.05);
  transition: 0.3s;
  box-shadow: 0 0 10px gray;
  text-shadow: 0 0 5px black;
}

@keyframes moveTrail {
  0% {
    transform: translateX(400%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.package-details-tab {
  padding: 5px 10px;
  /* background: lightgray; */
  border-radius: 25px;
  border: 2px solid #f26a2f;
  flex: none;
  font-weight: 600;
}

.package-details-tab:hover {
  background: #f26a2f;
  color: white;
}

.package-details-tab.active {
  background: #f26a2f;
  color: white;
}

.home-image-dialog {
  color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 40px;
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("./assets/images/hompopup.jpeg");
  height: 639px;
  width: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 10px;
}

.home-dialog-close {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid red;
  color: red;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
}

.package-inclueds {
  flex: none;
}

/* RESPONSIVE CSS */

@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }

  .container-1480 {
    max-width: 1480px;
  }
}

@media (max-width: 1399px) {
  .tour-page-tabing .tab-content.tab-content-main {
    padding: 30px 30px;
  }

  .property-hotel-pic {
    width: 300px;
    min-width: 300px;
  }

  .property-hotel-data {
    width: calc(100% - 300px);
  }

  .property-hotel-data-right {
    width: 210px;
    min-width: 210px;
    padding: 20px 20px 20px 10px;
    font-size: 14px;
  }

  .property-hotel-data-midd {
    width: calc(100% - 210px);
  }
}

@media (max-width: 1399px) and (min-width: 1200px) {
  .tour-info .container.container-1480 {
    max-width: calc(100% - 70px);
  }
}

@media (max-width: 1199px) {
  .banner-leftside h2 {
    font-size: 50px;
  }

  .banner-leftside h3 {
    font-size: 29px;
  }

  .banner-leftside p {
    font-size: 16px;
  }

  .banner-pic img {
    width: 426px !important;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 23px;
  }

  h4 {
    font-size: 20px;
  }

  .box-v h3 {
    font-size: 25px;
    margin-left: 50px;
  }

  .box-v img {
    max-height: 110px;
  }

  .packages-side-col {
    width: 320px;
  }

  .packages-left-col {
    width: calc(100% - 320px);
  }

  .packages-sidebar-box-title h5 {
    font-size: 15px;
  }

  .packages-fromfield.half-60 {
    width: 100%;
  }

  .packages-fromfield.half-40 {
    float: none;
    width: 100%;
  }

  .package-higlite-inclued {
    flex-direction: column;
  }

  .package-inclueds {
    margin-top: 20px;
    flex: none;
  }

  .tour-page-tabing ul.nav-tabs.nav-tabs-packmain {
    flex-direction: row;
    width: 100%;
  }

  .tour-page-tabing .tab-content.tab-content-main {
    width: 100%;
    padding: 20px 20px;
  }

  .tour-page-tabing {
    flex-direction: column;
  }

  .tour-page-tabing ul.nav-tabs.nav-tabs-packmain li button {
    padding: 7px 8px 11px 8px;
    font-size: 15px;
    border-left: 0;
    border-top: 5px solid transparent;
  }

  .property-hotel-items {
    flex-direction: column;
  }

  .property-hotel-pic {
    width: 100%;
    min-width: 100%;
  }

  .property-hotel-data {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .pune-image-container {
    height: 280px;
    background: url("assets/images/punemobile.jpg.jpg");
    background-size: cover;
    background-position: center;
  }

  .banner-pic img {
    width: 316px !important;
    max-width: 100%;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 22px;
  }

  .tabsameclss {
    width: 190px;
  }

  .festival-season div#myTabContent {
    width: calc(100% - 380px);
  }

  .tabsameclss li.nav-item button {
    padding: 7px 10px;
    font-size: 16px;
    gap: 13px;
    margin-bottom: 10px;
  }

  .box-v {
    padding: 20px 15px;
  }

  .box-v h3 {
    font-size: 19px;
    margin-left: 20px;
  }

  .testimon-col-left {
    padding-left: 0;
  }

  .owl-carousel.testimonial-owl {
    margin-top: 30px;
  }

  .testimonial-section .owl-theme .owl-nav {
    text-align: center;
  }

  .pagetitle h1 {
    font-size: 30px;
  }

  .breadrm {
    font-size: 14px;
  }

  .yearsof-success h4 span {
    font-size: 30px;
  }

  .packages-side-col {
    width: 100%;
  }

  .packages-left-col {
    width: 100%;
  }

  .mobile-toggle-filters {
    display: block;
    margin-bottom: 20px;
  }

  .shortby.shortby-fltr {
    background-color: #2ebad3;
    color: #fff;
    padding: 10px 10px;
    border-radius: 7px;
    font-weight: 600;
    text-align: center;
    width: 100%;
  }

  .side-filters {
    position: fixed;
    top: 0;
    z-index: 99;
    background-color: #fff;
    left: -100%;
    right: auto;
    border-radius: 0;
    display: block;
    max-height: 100vh;
    overflow-y: auto;
    transition: 0.2s;
    overflow: visible;
    overflow-y: auto;
    width: 500px;
    max-width: 100%;
  }

  .side-filters.filters-active {
    left: 0;
  }

  .mobile-filter-close {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2ebad3;
    color: #fff;
  }

  .filter-title {
    display: none;
  }
}

@media (max-width: 767px) {
  h2 {
    font-size: 24px;
  }

  .logo-sec {
    width: 60%;
  }

  .col-sm-8.menu-col {
    width: 40%;
    position: initial;
  }

  header .row {
    position: relative;
  }

  .toggle-form {
    position: absolute;
    top: 55px;
    right: 0px;
    left: 0;
    margin: 0px 12px;
  }

  .logo-site img {
    width: 170px;
  }

  .banner-text-boxes {
    flex-direction: column;
    text-align: center;
  }

  .page-not-found-text-boxes {
    flex-direction: column;
    text-align: center;
  }

  .banner-leftside h3 {
    font-size: 24px;
  }

  .banner-leftside p {
    font-size: 14px;
    margin-top: 0;
  }

  .banner-leftside h2 {
    margin: 0 0 7px 0;
  }

  .page-not-found-leftside p {
    margin-top: 0;
  }

  .page-not-found-leftside h5 {
    margin: 0 0 7px 0;
  }

  .discover-owl {
    text-align: center;
  }

  .arrow-top-right .owl-nav {
    position: static;
  }

  .tabsameclss {
    width: 100%;
  }

  .festival-season div#myTabContent {
    width: 100%;
    padding: 20px 0;
  }

  h5 {
    font-size: 18px;
  }

  .tabbingpcontt {
    padding: 13px;
  }

  .blog-box.blog-box-big .blog-contnt h5 {
    font-size: 18px;
  }

  .blog-contnt h5 {
    margin-bottom: 0px;
  }

  .blog-box.blog-box-big .blog-pic {
    height: 260px;
  }

  .blog-contnt {
    overflow-y: auto;
  }

  .box-v img {
    max-height: 70px;
  }

  .box-v {
    flex-direction: column;
    padding: 15px;
  }

  .box-v h3 {
    margin: 0 0;
    text-align: center;
    font-size: 16px;
    margin-top: 13px;
  }

  .paradise-box-row {
    flex-direction: column;
  }

  .paradise-box-col {
    width: 100%;
  }

  .dropmsg {
    max-width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .mailus-at {
    text-align: left;
  }

  .dropmsg p {
    font-size: 17px;
  }

  .mailus-at a {
    font-size: 14px;
  }

  .full-section h2 {
    margin-bottom: 15px;
  }

  .footer-logo img {
    height: 60px;
    width: auto;
  }

  copyright {
    font-size: 14px;
    line-height: normal;
  }

  .footer-last::before {
    top: 36px;
  }

  body {
    font-size: 15px;
    line-height: 22px;
  }

  .discouver-section h2 {
    text-align: center;
  }

  .altr-pics {
    margin-bottom: 10px;
  }

  .altr-about-row {
    padding: 30px 0;
  }

  .pagetitle {
    padding: 160px 0 30px 0;
  }

  .contact-form {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .contactpage-info {
    padding: 30px 0;
  }

  .adress-contctpag {
    padding: 25px;
  }

  .social-contac {
    flex-direction: column;
    padding: 17px 0 0 0;
    border-top: 1px solid #ddd;
  }

  .maps {
    padding-bottom: 60px;
  }

  .pagetitle h1 {
    font-size: 25px;
  }

  .tour-detail-top {
    flex-direction: column;
    padding: 30px 0;
    text-align: left;
  }

  .detiltitle-under {
    justify-content: flex-start;
  }

  .tour-detail-price {
    text-align: left;
    margin-top: 20px;
    width: 100%;
  }

  .days-desgin span {
    line-height: 28px;
  }

  .parckage-cities p {
    flex-wrap: wrap;
    gap: 10px;
  }

  ul.property-opti li {
    margin: 0 1px;
    min-width: 70px;
  }

  ul.property-opti li span {
    font-size: 12px;
  }

  .similarproduct-data ul.property-opti {
    gap: 0;
  }

  .Overview-meal-visa {
    flex-direction: column;
  }

  .Overview-mealbox {
    width: 100%;
  }

  .Overview-mealbox h6 {
    font-size: 15px;
  }

  .accordion-button {
    flex-wrap: wrap;
    position: relative;
  }

  .accordion-date-button {
    flex-wrap: wrap;
    position: relative;
  }

  .days-left {
    width: 100%;
    padding-left: 26px;
    margin-bottom: 5px;
  }

  .accordion-button h6 {
    font-size: 15px;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 27px;
  }

  .accordion-date-button h6 {
    font-size: 15px;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 27px;
  }

  .accordion-button::after {
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
    height: 25px;
    line-height: 22px;
  }

  .accordion-date-button::after {
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
    height: 25px;
    line-height: 22px;
  }

  .accordion .accordion-item::before {
    left: 0;
  }

  .accordion .accordion-date-item::before {
    left: 0;
  }

  .accordion-button h6 img {
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: -2px;
  }

  .accordion-date-button h6 img {
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: -2px;
  }

  .accordion-body {
    width: 100%;
    margin-left: auto;
    padding-left: 28px;
    padding-right: 0;
    padding-top: 0;
  }

  .accordion-date-body {
    width: 100%;
    margin-left: auto;
    padding-left: 28px;
    padding-right: 0;
    padding-top: 0;
  }

  .stay-tb ul {
    flex-direction: column;
    text-align: left;
    align-items: self-start;
  }

  .accordion-button {
    font-size: 15px;
  }

  .accordion-date-button {
    font-size: 15px;
  }

  .flight-details-box-in {
    flex-direction: column;
  }

  .flight-left,
  .flight-mid {
    width: 100%;
    text-align: center;
    padding: 0 0;
  }

  .hr-line {
    border: 0;
    border-left: 1px dashed #000;
    width: 1px;
    margin: 10px auto;
    display: block;
    height: 40px;
  }

  .flight-right {
    border: 0;
    border-top: 1px solid #ddd;
    padding: 10px 0;
    margin: 20px 0 0 0;
  }

  .middle-line {
    border-color: #000;
  }

  .conclusiton-box-data ul {
    padding: 0;
    margin: 0;
  }

  .nav-tabs-flight li button {
    padding: 6px 11px;
    margin: 0 3px;
  }

  .toolbar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .showing-tour {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .property-hotel-first {
    flex-direction: column;
    padding: 10px;
  }

  .property-hotel-data-midd {
    width: 100%;
    padding: 0;
  }

  .property-hotel-data-right {
    width: 100%;
    min-width: 100%;
    padding: 20px 0;
    border: 0;
    text-align: left;
  }

  .property-hotel-items {
    position: relative;
  }

  .property-hotel-data-right .share-wishlist {
    position: absolute;
    top: 0;
    gap: 5px;
    right: 20px;
    font-size: 0px;
  }

  .property-hotel-data-right .share-wishlist a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    line-height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .property-hotel-data-sec p {
    flex-wrap: wrap;
    gap: 0;
  }

  .property-hotel-data-sec p img {
    margin: 0 7px;
  }

  .property-hotel-items ul.property-opti li {
    min-width: 67px;
  }

  .submenu-items {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    height: 100%;
    background-color: #f7f7f7;
    padding: 50px 20px;
    overflow-y: auto;
    width: 100%;
    transition: 0.3s;
    z-index: 9;
  }

  .close-submenu {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 60px);
    padding: 10px 20px;
  }

  .close-submenu img {
    width: 20px;
  }

  .submenu-items.mobile-slide {
    left: 0;
  }

  .menu-main ul.nav-menu {
    width: 100%;
    padding: 50px 20px;
    height: 100%;
  }

  .menu-main {
    flex-direction: column;
  }

  .menu-main ul li a {
    font-size: 15px;
  }

  .submenu-nav {
    padding: 0;
  }

  .pegination {
    margin-bottom: 30px;
  }

  .blog-post,
  .blog-detail {
    padding: 25px 0px;
  }

  .blog-detail h2 br {
    display: none;
  }

  .blog-detail-img .col-md-6.col-sm-12 {
    margin-bottom: 20px;
  }

  .tour-list-detail h2 {
    font-size: 23px;
  }

  .tour-list-detail {
    padding-top: 30px;
  }

  .gateway-detail {
    padding: 25px 0px;
  }

  .gateway-detail .col-sm-12 {
    margin-bottom: 20px;
  }

  .gateway-detail-top .col-sm-12 {
    margin-bottom: 20px;
  }

  .css-doq0dk>div:nth-child(1) {
    opacity: 1 !important;
  }

  .css-doq0dk>div:nth-child(2) {
    left: 37% !important;
    opacity: 1 !important;
  }

  .css-doq0dk>div:nth-child(5) {
    opacity: 1 !important;
  }

  .css-doq0dk>div:nth-child(4) {
    left: 63% !important;
    opacity: 1 !important;
  }

  .css-doq0dk>div .item {
    width: 75vw;
  }

  .css-doq0dk>div .item .trail-slid-pic {
    width: 75vw;
  }

  .css-doq0dk>div .item .trail-slid-pic img {
    object-fit: cover;
  }

  .discover-slick .arrow {
    top: 345px;
  }

  .tour-page-tabing {
    top: 75px !important;
  }

  .home-image-dialog {
    height: 532px;
    width: 500px;
    border-radius: 5px;
  }
}

@media (max-width: 575px) {
  .home-image-dialog {
    height: 383px;
    width: 360px;
    border-radius: 5px;
  }

  .package-higlite ul li {
    width: 100%;
  }

  ul.property-opti li span {
    font-size: 10px;
  }

  .see-availbtn a.btn {
    padding: 0 20px;
    font-size: 15px;
  }

  .property-hotel-data-sec {
    padding: 9px 10px;
    font-size: 14px;
  }

  .cusomize-holiday-tabbing li.nav-item {
    width: 50%;
    margin-bottom: 15px;
  }

  .cusomize-holiday-tabbing .nav-pills .nav-link {
    width: 100%;
    padding: 10px 0px;
  }

  .cusomize-holiday-tabbing ul#pills-tab {
    margin-bottom: 0px !important;
  }

  .banner-text h1 {
    font-size: 40px;
  }

  .cusomize-holiday-tabbing,
  .Personalized-package-section {
    padding: 25px 0px;
  }

  .css-doq0dk>div:nth-child(1) {
    opacity: 1 !important;
  }

  .css-doq0dk>div:nth-child(2) {
    left: 37% !important;
    opacity: 1 !important;
  }

  .css-doq0dk>div:nth-child(5) {
    opacity: 1 !important;
  }

  .css-doq0dk>div:nth-child(4) {
    left: 63% !important;
    opacity: 1 !important;
  }

  .css-doq0dk>div:nth-child(3) .item {
    width: 90vw;
  }

  .css-doq0dk>div:nth-child(3) .item .trail-slid-pic {
    width: 90vw;
  }

  .css-doq0dk>div .item .trail-slid-pic img {
    object-fit: cover;
  }

  .nav-btn {
    display: none;
  }

  .whatsapp-button {
    display: none;
  }

  .inquiry-button {
    display: none;
  }

  .mobile-inquiry-btn {
    display: block;
  }

  .mobile-whatsapp-btn {
    display: block;
  }
}